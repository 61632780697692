import React from 'react'
import Styled, {keyframes} from 'styled-components'
import PropTypes from 'prop-types'

import  {colors} from '../styles/Styles'

const spin = keyframes`
from {
	transform: rotate(0deg);
}
to {
	transform: rotate(360deg);
}
`

const LoadingStyles = Styled.div`
	
	display:flex;
	justify-content:center;
	
	.spinner {
		width:calc(3vw + 3vh);
		height:calc(3vw + 3vh);
		
		border-radius:100%;
		
		border:3px solid ${props => props.color ? props.color : colors.red};
		
		border-left:none;
		border-bottom:none;
		border-top:none;
		
		transform-origin:50% 50%;
		animation: ${spin} 2s linear infinite;
	}
	
`

function Loading ({color}) {
	return (
		<LoadingStyles color={color}>
			<div className="spinner"/>
		</LoadingStyles>
	)//
}

Loading.propTypes = {
	color:PropTypes.string,
}

export default Loading
