import * as React from "react"
import PropTypes from 'prop-types'

import {colors} from '../styles/Styles'

function Quote({width, height, color, ...props}) {
	return (
		<svg
		width={!!width ? width : 45}
		height={!!height ? height : 40}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
		>
		<path
		d="M45 .356c-6.34-1.032-11.108.18-14.304 3.64-3.196 3.458-4.794 8.568-4.794 15.33V40H45V20.1h-9.897c-.412-3.51.052-6.4 1.392-8.672 1.392-2.27 3.866-3.277 7.422-3.02L45 .357zm-25.902 0C12.758-.676 7.99.536 4.794 3.996 1.598 7.453 0 12.563 0 19.325V40h19.098V20.1H9.2c-.412-3.51.052-6.4 1.392-8.672 1.392-2.27 3.866-3.277 7.422-3.02L19.098.357z"
		fill={!!color ? color : colors.white}
		/>
		</svg>
	)
}

Quote.propTypes = {
	color:  PropTypes.string,
	width:  PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	height: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
}

export default Quote
