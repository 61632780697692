import React from 'react'
import Styled, {keyframes} from 'styled-components'

import {colors} from '../styles/Styles'

import Bocca from '../images/BoccaVulcano'

const animation = ({duration, delay, width}) => {
	const step0 = Math.floor(100*duration/3/(duration+delay))
	const step1 = Math.floor(100*duration/2/(duration+delay))
	const step2 = Math.floor(100*duration*2/3/(duration+delay))
	const step3 = Math.floor(100*duration/(duration+delay))
	
// 	console.log(step1, step2)
	
	const cleanWidth = typeof width === "number" ? width + "px" : width
	
	return keyframes`
	0% {
		opacity:0;
		width:0px;
		left:0;
		right:100%;
	}
	${step0}%{
		left:0;
	}
	${step1}% , ${step2}% {
		width:calc(${cleanWidth} / 2);
		right:0;
		opacity:1;
	}
	${step3}% , 100% {
		opacity:0;
		width:0px;
		right:0;
		left:100%;
	}
	`
}//

const LineContainer = Styled.div`

// ${props=>{props.delay && console.log("Line duration, delay & sum",props.duration/1000, props.delay/1000, (props.duration+props.delay)/1000);return ''}}

height:calc(0.17vw + 0.28vh);

transform-origin:0% 0%;
transform:rotate(${props=>!!props.angle ? props.angle : 0}deg);
position:relative;
width:${props => props.width ? ( typeof props.width === "number" ? props.width+'px' : props.width ) : '60vw'};

.line{
	background:${props=>!!props.color ? props.color : colors.black};
	height:100%;
	position:relative;
	top:0;
	
	opacity:${props=>props.animated?0:1};
	
	animation-name:${props=>props.animated ? animation(props) : 'none'};
	animation-duration:${props=>props.duration/1000}s;
	animation-timing-function:linear;
	animation-delay:${props=>props.delay/1000}s;
	animation-iteration-count:infinite;
	
}
`

const Line = props => <LineContainer {...props}><div className='line'/></LineContainer>

const Container = Styled.div`
width:100%;
height:65vh;

.linesContainer {
	height:100%;
	display:flex;
	justify-content:center;

	overflow:hidden;
}

position:relative;
margin:9vw 0 0;	//130 0

@media (orientation:portrait){
	height:20vh;
	margin:25.64vw 0 0;	//100
}

.michael{
	position:relative;
	width:0px;

	.bocca{
		position:absolute;
		top:0;
		width:0;
		display:flex;
		justify-content:center;

		.boccaCont {
			width:calc(5.90vw + 9.44vh);	//170
		}
	}
}
`

const Background = Styled.div`
	position:absolute;
	width:100%;
	height:100%;
	clip-path: polygon(50% 0%, 0 calc(${props => props.tan} * 50vw), 0 101%, 100% 101%, 100% calc(${props => props.tan} * 50vw) );
	background:white;
`

//normal distribution
//https://codepen.io/luisiniMagigi/pen/LYzYbNP
function gaussian(mean, stdev) {
	var y2;
	var use_last = false;
	return function() {
		var y1;
		if (use_last) {
			y1 = y2;
			use_last = false;
		} else {
			var x1, x2, w;
			do {
				x1 = 2.0 * Math.random() - 1.0;
				x2 = 2.0 * Math.random() - 1.0;
				w = x1 * x1 + x2 * x2;
			} while (w >= 1.0);
			w = Math.sqrt((-2.0 * Math.log(w)) / w);
			y1 = x1 * w;
			y2 = x2 * w;
			use_last = true;
		}
		
		var retval = mean + stdev * y1;
		if (retval > 0)
			return retval;
		return -retval;
	}
}

function getRandom(min, max){
	let mean = (max+min)/2
	let stdev = (max-min)/100*60
	
	let value = mean
	
	do{
		value = gaussian(mean,  stdev)()
	}
	while(value < min || value > max)
	
	return Math.floor(value)
}

function RandomLine (props){
	const angle=getRandom(1, 11.92)*10+30.4,
	duration=getRandom(props.duration.min, props.duration.max),
	delay=getRandom(props.delay.min, props.delay.max)
	
	const [tik, tok] = React.useState(true)
// 	console.log(" App duration, delay & sum", duration/1000, delay/1000, (duration+delay)/1000)
	setTimeout(()=>tok(!tik), duration+delay)
	
	return <Line color={colors.red} angle={angle} duration={duration} delay={delay} animated={true} width={typeof window !== "undefined" && window.innerWidth > window.innerHeight ? '67vh' : '15vh'}/>
}//

function Volcano(){

	const angle = 30.4

	return <Container>
		<Background tan={Math.tan(angle * Math.PI / 180)}/>
		{/* serve perchè Container è flexbox, non posso posizionare linee*/}
		<div className="linesContainer">
		<div className="michael">
			<Line angle={angle} style={{transformOrigin:'top left'}}/>
			<Line angle={180-angle} style={{transformOrigin:'bottom left', top:'calc(calc(0.17vw + 0.28vh) * -2)'}}/>
			<div className="bocca">
				<div className="boccaCont">
				<Bocca width="calc(5.90vw + 9.44vh)" height="calc(3.44vw + 5.50vh)"/>
				</div>
			</div>
			<RandomLine duration={{min:4000, max:5500}} delay={{min:2000, max:6000}}/>
			<RandomLine duration={{min:4000, max:5500}} delay={{min:6000, max:10000}}/>
		</div>
		</div>
	</Container>
}//

export default Volcano
