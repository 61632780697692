import * as React from "react"
import PropTypes from 'prop-types'

import {colors} from '../styles/Styles'

function SvgComponent({width, height, strokeWidth, props}) {
	return (
		<svg
		width={!!width ? width : 170}
		height={!!height ? height : 99}
		viewBox="0 0 170 99"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
		>
		<path
		d="M84.931 95.231L5.003 49.87 84.906 3.027l80.051 46.993-80.026 45.211z"
		fill={colors.red}
		stroke={colors.black}
		strokeWidth={!!strokeWidth ? strokeWidth : 5}
		/>
		</svg>
	)
}

SvgComponent.propTypes = {
	color:  PropTypes.string,
	width:  PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	height: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	strokeWidth: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
}

export default SvgComponent
