import React from 'react';
import Styled from 'styled-components'

import { StaticQuery, graphql, Link} from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import emailjs from 'emailjs-com'

import Seo from '../components/Seo'
import Main, {colors} from '../styles/Styles'
import Header from '../components/Header'
import Button from '../components/Button'
import Footer from '../components/Footer'
import Md from '../components/Markdown'
import Loading from '../components/Loading'
import Volcano from '../components/Volcano'

import Quote from '../images/Quote'

const query = graphql`
query {
	strapiHomePage {
		Title
		MainTitle {
			data {
				childMarkdownRemark {
					html
				}
			}
		}
		Description {
			data {
				childMarkdownRemark {
					html
					rawMarkdownBody
				}
			}
		}
		services {
			Url
			Title
			Cover {
				alternativeText
				childStrapiCloudinaryBridge {
					gatsbyImageData
				}
			}
		}
	}
}
`;


const ServiceButtonStyles = Styled.div`

display:flex;
flex-direction:column;
align-items:center;

width:100%;
height:100%;

img {
	width:100%;
}

h5 {
	font-family:Karla;
	font-size:1.74vw;	//25
	line-height:1.74vw;	//25
	font-weight:400;
	
	margin:.69vw 1.74vw;	//10 25
	
	white-space:break-spaces;
	
	@media (orientation:portrait){
		font-size:6.41vw;	//25
		line-height:6.41vw;	//25
		margin:2.56vw 6.41vw;	//10 25
	}
}

p {
	font-size:1.25vw;	//18
	line-height:1.74vw;	//25
	margin:.35vw 1.74vw 0;	//5 25
	color:${() => colors.red};
	
	@media (orientation:portrait) {
		font-size:4.62vw;	//18
		line-height:6.41vw;	//25
		margin:1.28vw 6.41vw 0;	//5 25
	}
}
`

function ServiceButton({Title, Cover}){
	
	const [hover, setHover] = React.useState(false)
	
	return (
		<ServiceButtonStyles
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
			hover={hover}
			>
			{!!Cover &&
			<GatsbyImage image={getImage(Cover.childStrapiCloudinaryBridge)} alt={Cover.alternativeText} />
			}
			<h5>{Title.toLowerCase()}</h5>
		</ServiceButtonStyles>
	)//
}


const HomePage = ({strapiHomePage : hp}) => {
	
	const [minimized, setMinimized] = React.useState(false)
	const [loading, setLoading] = React.useState(false)
	const [mailText, setMailText] = React.useState(null)
	const [scrollDirection, setScrollDirection] = React.useState(0)
	
	const formRef = React.useRef(null)
	
	React.useEffect(()=>{
		
		const onScroll = e => {
			
			const scrollTop = e.target.scrollingElement.scrollTop
			
			const treshhold = 360

			if (scrollTop > treshhold &&  !minimized) setMinimized(true)
			else if (scrollTop < treshhold && !!minimized) setMinimized(false)
		}
		
		setMinimized(document.scrollingElement.scrollTop > 100)
		document.addEventListener('scroll', onScroll)
		
		return () => {
			document.removeEventListener('scroll', onScroll)
		}
	}, [minimized])
	/*

	React.useEffect(()=>{


		function preventDefault(e) {
			e.preventDefault();
			e.stopPropagation();
		}


		// modern Chrome requires { passive: false } when adding event
		var supportsPassive = false;
		try {
		window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
			get: function () { supportsPassive = true; }
		}));
		} catch(e) {}

		var wheelOpt = supportsPassive ? { passive: false } : false;
		var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';


		function disableScroll() {
			window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
			window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
			window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
		}

		function enableScroll() {
			window.removeEventListener('DOMMouseScroll', preventDefault, false);
			window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
			window.removeEventListener('touchmove', preventDefault, wheelOpt);
		}

		const onScroll = event => {

				var delta = 0;
/*
				if (event.wheelDelta) delta = event.wheelDelta > 0 ? 1 : event.wheelDelta < 0 ? -1 : 0//event.wheelDelta / 120;
				else if (event.detail) delta = event.detail > 0 ? 1 : event.detail < 0 ? -1 : 0 //-event.detail / 3;
* /
				if (event.wheelDelta) delta = event.wheelDelta / 120;
				else if (event.detail) delta = -event.detail / 3;

				var time = 1000;
				var distance = 300;

				var oldSrcollTop = document.getElementsByTagName('html')[0].scrollTop
				document.getElementsByTagName('html')[0].scrollTop = oldSrcollTop + (distance * delta)

			preventDefault(event)
			event.returnValue = false;
		}

		document.addEventListener('scroll', onScroll)

		return () => {
			enableScroll()
			document.removeEventListener('scroll', onScroll)
		}
	}, [])
*/
	function submit(e){
		
		e.preventDefault();

		setLoading(true)
		
		const formData = new FormData(e.target)
		
		var data = {}
		
		//fucking iterators
		for(const [key, value] of formData.entries()) {
			data[key] = value
		}
		
		console.log(data )
		
		emailjs.send(
			process.env.EMAIL.serviceId,
			process.env.EMAIL.templateId,
			data,
			process.env.EMAIL.userId
		)
		.then(
			function(response) {
				console.log('sent email: ', response.status, response.text);
				setMailText("Grazie! Abbiamo ricevuto il tuo messaggio.")
				setLoading(false)
			}, function(error) {
				console.warn('Error sending email', error);
				alert("Non siamo riusciti a ricevere il tuo messaggio: "+error.status+" - "+error.text)
				setLoading(false)
			}
		);
	}
	/*
	const [description, setDescription] = React.useState(hp.Description.da)
	
	React.useEffect(() => {
		if (window.innerWidth < window.innerHeight)
			setDescription(description => description.replace(/(, ?)\n/g, '$1'))
	}, [hp.Description])
	*/
	return (
		<HomePageStyles serviceNum={hp.services.length}>
		
			<Seo title={hp.Title} description={hp.Description.data.childMarkdownRemark.rawMarkdownBody} type="website"/>
			
			<Header home={true} minimized={minimized}/>
			
			<div className="container first">
			
				<Md>{hp.MainTitle.data.childMarkdownRemark.html}</Md>
				
				<Link to="#contactUs" className="button">lavoriamo insieme</Link>
			
			</div>
			
			<div className="volcanoContainer">
				<Volcano/>
			</div>
			
			<div className="content">

			<div className="container">
				
				<Md className="description">{hp.Description.data.childMarkdownRemark.html}</Md>
				
				<div className="services">
				{//
					hp.services.map(service => 
					<Button url={'services#'+service.Url} title={service.Title} key={service.Url}>
						<ServiceButton {...service}/>
					</Button>
				)}
				</div>
				
			</div>
			
			<section id="contactUs">
				
				<h2>Contattaci</h2>
				
				<div className="flexContainer">
					
					<div className="quote">
						<Quote/>
						<p className="quotation">
						to improve is to change.<br/>
						to be perfect<br/>
						is to change often.
						</p>
						<div className="line"/>
						<p>Winston Churchill</p>
					</div>
					
					<div className="form">
						{!!loading ? 
						<Loading color={colors.white}/>
						:
						!!mailText ?
						mailText
						: 
						<form ref={formRef} onSubmit={submit}>
							<div className="firstRow">
								<div className="name">
									<label htmlFor='name'>nome</label>
									<input type='text' name='name' id='name' required/>
								</div>
								
								<div className="surname">
									<label htmlFor='surname'>cognome</label>
									<input type='text' name='surname' id='surname'/>
								</div>
							</div>
							
							<label htmlFor='company'>nome azienda</label>
							<input type='text' name='company' id='company'/>
							
							<label htmlFor='email'>email</label>
							<input type='text' name='email' id='email' required/>
							
							<label htmlFor='message'>messaggio</label>
							<textarea name='message' id='message' required/>
							
							<input type='checkbox' name='comunications' id='comunications' required/>
							<label htmlFor='comunications'>Accetto di ricevere altre comunicazioni dal team di vulcano</label>
							<br/>
							
							<input type='checkbox' name='privacy' id='privacy' required/>
							<label htmlFor='privacy'>Consento a vulcano di archiviare e elaborare i miei dati personali</label>
							
							<br/>
							<input type='submit' name='submit' id='submit' value="invia"/>
						</form>
						}
					</div>
				</div>
			</section>
			
			</div>

			<Footer/>
			
		</HomePageStyles>
	)//
}

const HomePageStyles = Styled(Main)`
	
	&, .container  {
		text-align:center;
		display:flex;
		flex-direction:column;
		align-items:center;
		
		white-space:pre;
		
		@media (orientation:portrait){
			white-space:break-spaces;
			text-align:left;
		}
	}
	
	.container.first {
		position:fixed;

		.markdownContainer {

			margin-top:17.4vw;	//250

			@media (orientation:portrait){
				margin:43.59vw 0 7.69vw;	//170 30
			}
		}
	}

	.volcanoContainer {
		width:100%;
		margin-top:60vh;
	}

	.content {
		background:white;
		z-index:10;
	}

	@media (orientation:portrait){
		padding:0;
		
		.container{
			padding:0 3.85vw;
		}
	}
	
	a.button {
		
		cursor:pointer;
		
		display: flex;
		justify-content:center;
		align-items:center;
		
		padding: .7vw calc(.7vw * 4);	//10 40
		
		width: 16.3vw;
		height: 2.9vw;
		
		font-size:1.25vw;	//18
		
		@media (orientation:portrait){
			padding: 5.13vw calc(5.13vw * 5);
			width: 100%;
			height: 15.38vw;
			
			border-radius:7.69vw;
			
			font-size:4.62vw;
		}
		
		border: .14vw solid ${()=>colors.red};
		box-sizing: border-box;
		border-radius: 1.45vw;
		
		background:${() => colors.white};
		color:${() => colors.black};
		
		transition:.2s;
		
		&:hover, &:focus {
			background:${() => colors.red};
			color:${() => colors.white};
		}
	}
	
	.description {
		margin:13.89vw 0; //200
		
		@media (orientation:portrait){
			margin:51.28vw 0; //200
		}
	}
	
	.services {
		box-sizing:border-box;
		height:calc((100vw - 11.5vw * 2) / ${props => props.serviceNum} - 2vw);
		width:100%;
		padding:0 11.5vw;
		
		margin:34.72vw 0;//500
		
		display:flex;
		gap:2vw;
		
		@media (orientation:portrait){
			margin:0;
			flex-direction:column;
			padding:0;
			
			height:auto;
			
			gap:7.69vw;
		}
		
		.styledButton {
			width:calc((100vw - 11.5vw * 2) / ${props => props.serviceNum} - 2vw);
			height:100%;
			
			@media (orientation:portrait){
				width:100%;
				height:calc(100vw - 3.85vw * 2 );
			}
		}
	}
	
	#contactUs {
		background: ${() => colors.red};
		
		box-sizing:border-box;
		width:100%;
		padding:8.96vw 11.5vw;	//129 165
		
		@media (orientation:portrait){
			padding:20.51vw 3.85vw;
			
			margin-top:51.28vw;
		}
		
		.flexContainer {
			display:flex;
			justify-content:space-between;
			width:100%;
			
			@media (orientation:portrait){
				flex-direction:column-reverse;
			}
		}
		
		h2 {
			color:${() => colors.white};
			margin:0 0 5.56vw;
			text-align:center;
			
			@media (orientation:portrait){
				margin:0 0 7.69vw;
			}
		}
		
		.quote {
			display:flex;
			flex-direction:column;
			align-items:center;
			
			flex-basis:40%;
			
			color:${() => colors.white};
			
			text-align:center;
			
			.line {
				border: .14vw solid ${() => colors.white};
				width:21.88vw;
				
				@media (orientation:portrait){
					border-width:.51vw;
					width:80.77vw;
				}
			}
			
			.quotation {
				font-weight: bold;
				font-size: 2.08vw;	//30
				line-height: 2.43vw;	//35
				text-align: center;
				margin:1.39vw 0;	//20
				
				@media (orientation:portrait){
					font-size: 7.69vw;	//30
					line-height: 8.97vw;	//35
					margin:5.13vw 0;	//20
				}
			}
			
			@media (orientation:portrait) {
				margin-top:25vw;
			}
		}
		
		.form {
			text-align:left;
			flex-basis:50%;
			
			@media (orientation:portrait){
				width:100%;
			}
			
			form {
				.firstRow {
					
					width:100%;
					
					display:flex;
					justify-content:space-between;
					
					div {
						flex-basis:46%;
					}
				}
				
				input[type=text], textarea, input[type=submit] {
					all:unset;
					display:block;
					background:${() => colors.white};
					border-radius: .35vw;	//5
					font-size: max(16px, 1.11vw);
					line-height: max(22px, 1.53vw);
					font-family:Manrope;
					color:${() => colors.black};
					
					box-sizing:border-box;
					width:100%;
					padding:.9vw .69vw 1.04vw;	//13 20 15
					margin:.35vw 0 1.74vw;	//5 25
					
					resize:none;
					white-space: break-spaces;
					
					@media (orientation:portrait){
						border-radius: 1.28vw;
						margin:1.28vw 0  6.41vw;
						padding:3.33vw 2.56vw 3.85vw;
					}
				}
				
				input[type=checkbox] {
					margin:0 .69vw 1.25vw 0;	//10 18
					
					@media (orientation:portrait){
						margin:0 2.56vw 8vw 0;
					}
				}
				
				input[type=submit] {
					width:auto;
					padding:.5vw 2.22vw;	//7 32
					margin:2.22vw 0;	//32
					
					transition:.2s;
					
					border: .14vw solid ${() => colors.red};	//2
					
					cursor:pointer;
					
					&:hover, &:focus {
						background:${() => colors.red};
						color:${() => colors.white};
						border-color:${() => colors.white};
						
						text-decoration:underline;
					}
					
					@media (orientation:portrait){
						width:100%;
						text-align:center;
						
						padding:2.82vw 0;
						
						margin-top:12.82vw;	//50
					}
				}
				
				label {
					white-space:break-spaces;
					display: inline-block;
					vertical-align: top;
					
					color:${() => colors.white};
					
					@media (orientation:portrait){
						width:90%;
					}
				}
			}
		}
	}
`

const IndexPage = () => (
	<StaticQuery
	query={query}
	render={HomePage}
	/>
);//

export default IndexPage;
